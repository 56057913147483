<template lang="pug">
	div.page-targeting
		loading(:active.sync="busy", :is-full-page="true")
		.widget(v-if="offer")
			.widget-header
				h1.title Offer Optimizations
			.widget-body
				.row
					.col-sm-6
						.form-group
							label Offer
							.input-group
								span.form-control [{{offer.id}}] {{ offer.name }}
								.input-group-append
									router-link.input-group-text(target="_blank", :to="{name:offer.type==='complex' ? 'complex-edit' : 'offer-edit', params:{id:offer.id}}")
										i.la.la-search
				.row
					.col-sm-6
						.form-group
							label Advertiser
							.input-group
								span.form-control [{{advertiser.id}}] {{ advertiser.name }}
								.input-group-append
									router-link.input-group-text(target="_blank", :to="{name:'advertiser-edit', params:{id:advertiser.id}}")
										i.la.la-search

		//.widget
			.widget-header
				h1.title Blocked Sources
			.widget-body
				.row
					.col-sm-6
						h4 Rules
						pre {{ routes }}
					.col-sm-6
						h4 Blacklist
						pre {{ blacklist }}

		div(v-if="offer")
			reports-widget(:offer_id="offer.id")
		//		.widget(v-if="offer")
		//			.widget-header
		//				h1.title Statistics
		//			.widget-body
		//				form.form.form-inline.ov-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
		//					.form-row-main
		//						.form-group
		//							label Group By
		//							select.form-control(v-model="filters.group_by")
		//								option(v-for="t in groupByOptions", :value="t.v") {{ t.t }}
		//						.form-group.fg-publisher
		//							label Publishers ({{ visiblePublisherOptions.length }})
		//								b-checkbox(switch="", v-model="publisherOptionsArchive")
		//							multiselect(:multiple="true", :options="visiblePublisherOptions", v-model="filters.publisher_id",
		//								track-by="v", label="t", deselect-label="", select-label="")
		//						ov-date-time-picker(v-model="filters.datePicker")
		//						//.form-group
		//							label Dates
		//							date-range-picker(:localeData="datePickerOptions.locale", :ranges="datePickerOptions.ranges",
		//								v-model="filters.dateRange", :time-picker="true", :time-picker24-hour="true", :time-picker-increment="1")
		//						//.form-group
		//							label Timezone
		//							select.form-control(v-model="filters.timezone")
		//								option(v-for="t in timeZoneOptions", :value="t.v") {{ t.t }}
		//					.form-row-end
		//						.form-group
		//							label &nbsp;
		//							div
		//								b-button-group
		//									b-dropdown(variant="primary", right, split, text="Go", @click="loadData()")
		//										b-dropdown-item(@click="loadData(true)")
		//											i.la.la-download
		//											| Export
		//								//| &nbsp;
		//							//button.btn.btn-secondary(type="button", @click="loadData(true)") Export
		//								i.la.la-download
		//				//pre {{ filters.offer_id }}
		//
		//				p.clearfix &nbsp;
		//				paginate(:paginator="paginate", @update-page-size="loadData")
		//
		//				.ov-table-wrapper
		//					table.table.table-bordered.tbl-statistics
		//						thead
		//							tr
		//								th(v-if="filters.daily") Date
		//								template(v-for="(c, i) in columns")
		//									th.col-entity(v-if="!c.hidden")
		//										.text.has-buttons
		//											span(v-if="c.e") {{ c.e.toUpperCase() }}
		//											span(v-if="!c.e") {{ c.label || c.f }}
		//											a.toggle-mode(href="javascript:void(0);", @click="toggleViewMode(c, i)")
		//												i.la.la-arrows-h
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="impressions") Imp.
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="clicks") Clicks
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="blocked_clicks") Blocked
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="passed_clicks") Passed
		//								ov-th.c.col-cv(@sort="doSort()", :sorter="sortBy", field="conversions") Cv
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="reported_conversions") CvR
		//								th.c CR(%)
		//								th.c R CR(%)
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="events") Evts
		//								- var i = 1;
		//								while i <= 10
		//									ov-th.m.col-evt.text-center(@sort="doSort()", :sorter="sortBy", :field="'e" + i + "'")
		//										.text.text-center()= 'E' + i
		//									- i++
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="revenue") Rev
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="cost") Cost
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="profit") Prof
		//								ov-th.c(@sort="doSort()", :sorter="sortBy", field="profit_percent") %
		//								th &nbsp;
		//						tbody.summary
		//							tr(v-if="summary")
		//								td.c(v-if="filters.daily") &nbsp;
		//								template(v-for="c in columns")
		//									td.c.col-entity(v-if="!c.hidden") &nbsp;
		//								td.c {{ summary.impressions.toLocaleString() }}
		//								td.c {{ summary.clicks.toLocaleString() }}
		//								td.c {{ summary.blocked_clicks.toLocaleString() }}
		//								td.c {{ summary.passed_clicks.toLocaleString() }}
		//								td.c.col-cv {{ summary.conversions.toLocaleString() }}
		//								td.c {{ summary.reported_conversions.toLocaleString() }}
		//								td.c {{ summary.cr }}
		//								td.c {{ summary.reported_cr }}
		//								td.c {{ summary.events }}
		//								- var i = 1;
		//								while i <= 10
		//									td.c
		//										div.v(title="E" + i)= '{{summary.e' + i + '}}'
		//									- i++
		//								td.c.text-revenue {{ summary.revenue }}
		//								td.c.text-cost {{ summary.cost }}
		//								td.c.text-profit {{ summary.profit }}
		//								td.c.col-profit-percent {{ summary.profit_percent }}
		//								td &nbsp;
		//						tbody
		//							tr(v-if="records.length===0")
		//								td(colspan="24") No matching records were found
		//							tr(v-for="r in records", v-bind:key="r.key")
		//								td.nowrap(v-if="filters.daily") {{ r.group_date }}
		//								template(v-for="c of columns")
		//									td.col-entity(v-if="!c.hidden", v-bind:key="r.key+'_'+(c.e || c.f)", :class="getColClass(r, c)")
		//										entity.no-bg(v-if="c.e", :id="r[c.e+'_id']", :name.sync="r[c.e+'_name']", :type="c.e",
		//											:mode="c.mode", :data="r[c.e]",
		//											:max-width="c.width", @click="appendEntity(r,c)", @dblclick="selectEntity(r,c)")
		//										span(v-if="!c.e") {{ c.l ? r[c.l] : r[c.f] }}
		//								//td(v-for="(c, i) in r.columns")
		//								//	entity(:type="columns[i].e", :id="c.id", :name.sync="c.name",
		//								//		:mode="columns[i].mode",
		//								//		:max-width="columns[i].width", @click="appendEntity(c,i)", @dblclick="selectEntity(c,i)")
		//								td.c {{ r.impressions.toLocaleString() }}
		//								td.c {{ r.clicks.toLocaleString() }}
		//								td.c {{ r.blocked_clicks.toLocaleString() }}
		//								td.c {{ r.passed_clicks.toLocaleString() }}
		//								td.c.col-cv
		//									router-link(:to="{name:'conversions', query: getURLFiltersParams(r)}") {{ r.conversions }}
		//								td.c
		//									router-link(:to="{name:'conversions', query: getURLFiltersParams(r, {reported: 1})}") {{ r.reported_conversions }}
		//								td.c {{ r.cr }}
		//								td.c {{ r.reported_cr }}
		//								td.c
		//									router-link(:to="{name:'events', query: getURLFiltersParams(r)}") {{ r.events }}
		//								- var i = 1;
		//								while i <= 10
		//									td.col-evt(:class="{'col-conv':r.event_idx_conversion==" + i + ", 'col-kpi': r.event_idx_kpi==" + i + "}")
		//										div(v-if="r.event_list && r.event_list.length >= " + i, v-b-tooltip.hover, :title="r.event_list[" + i + "-1].label || r.event_list[" + i + "-1].name")
		//											span.v= '{{r.e' + i + '}}'
		//										//span &nbsp;
		//										// [ngbTooltipDisabled]="r.event_list.length < "+i
		//
		//										//span.v(*ngIf="r.event_list.length < "+i)= '{{r.e'+i+'}}'
		//										//span.v(*ngIf="r.event_list.length >= "+i, [matTooltip]="r.event_list["+i+"-1].name")= '{{r.e'+i+'}}'
		//										//span.t(*ngIf="r.event_list.length >= "+i)= '({{r.event_list['+i+'-1].name}})'
		//									- i++
		//								td.c.text-revenue {{ r.revenue }}
		//								td.c.text-cost {{ r.cost }}
		//								td.c.text-profit {{ r.profit }}
		//								td.c.col-profit-percent {{ r.profit_percent }}
		//								td.actions
		//									div(v-if="filters.group_by=='subsource'")
		//										a.btn.btn-sm.btn-secondary(@click="toggleSubsource(r, 'pause')", v-b-tooltip.hover, title="Pause", v-if="r.status === 'active'")
		//											i.la.la-pause
		//										a.btn.btn-sm.btn-secondary(@click="toggleSubsource(r, 'play')", v-b-tooltip.hover, title="Play", v-if="r.status === 'paused' && r.default_rule && r.routes.length == 1")
		//											i.la.la-play
		//										a.btn.btn-sm.btn-secondary(@click="showRulesModal(r)", v-b-tooltip.hover, title="Rules")
		//											i.la.la-search
		//											span.badge(v-if="r.routes.length") {{ r.routes.length }}
		//										span.blacklisted(v-if="r.blacklist")
		//											i.la.la-flag
		//
		//						tfoot.summary
		//							tr(v-if="summary")
		//								td.c(v-if="filters.daily") &nbsp;
		//								template(v-for="c in columns")
		//									td.c.col-entity(v-if="!c.hidden") &nbsp;
		//								td.c {{ summary.impressions.toLocaleString() }}
		//								td.c {{ summary.clicks.toLocaleString() }}
		//								td.c {{ summary.blocked_clicks.toLocaleString() }}
		//								td.c {{ summary.passed_clicks.toLocaleString() }}
		//								td.c {{ summary.conversions.toLocaleString() }}
		//								td.c {{ summary.reported_conversions.toLocaleString() }}
		//								td.c {{ summary.cr }}
		//								td.c {{ summary.reported_cr }}
		//								td.c {{ summary.events }}
		//								- var i = 1;
		//								while i <= 10
		//									td.c
		//										div.v(title="E" + i)= '{{summary.e' + i + '}}'
		//									- i++
		//								td.c.text-revenue {{ summary.revenue }}
		//								td.c.text-cost {{ summary.cost }}
		//								td.c.text-profit {{ summary.profit }}
		//								td.c.col-profit-percent {{ summary.profit_percent }}
		//								td &nbsp;
		//
		//				//, @update-page-size="loadData"
		//				paginate(:paginator="paginate")

</template>
<style lang="scss">
.blacklisted {
  color: #000;
  font-size: 16px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  display: inline-block;
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';
import SubsourceRulesModal from '../modals/SubsourceRulesModal';

export default {
  name: 'PlacementTargeting',
  components: {},
  async beforeRouteEnter(to, from, next) {
    let offer = {};
    let advertiser = null;

    // let advertiserOptions = await Vue.ovData.advertiser.getOptions();
    let publisherOptions = await Vue.ovData.publisher.getOptions();

    let entityId = +to.params.id;
    offer = await Vue.ovData.offer.get(entityId, true);

    if (offer.advertiser_id) {
      advertiser = await Vue.ovData.advertiser.get(offer.advertiser_id);
    }

    next(async (vm) => {
      vm.publisherOptions = publisherOptions;
      vm.advertiser = advertiser;
      vm.offer = offer;
      vm.loadRoutes();
      return vm;
    });
  },
  computed: {
    visiblePublisherOptions() {
      return this.publisherOptions.filter((a) => {
        return this.publisherOptionsArchive || a.status;
      });
    },
  },
  data() {
    return {
      busy: false,
      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 30,
        onPageChange: () => {
          this.loadData();
        },
      },
      records: [],
      routes: {},
      blacklist: [],
      blacklistHash: {},
      publisherOptionsArchive: false,
      publisherOptions: [],
      filters: {
        keyword: '',
        cohort: false,
        daily: false,
        publisher_id: [],
        group_by: 'adv_subsource',
        datePicker: {
          timezone: 0,
          dateRange: {
            startDate: null,
            endDate: null,
          },
        },
      },
      sortBy: {
        field: 'conversions',
        direction: 'desc',
        sort: null,
      },
      summary: null,
      columns: [],
      placement: null,
      offer: null,
      publisher: null,
      advertiser: null,
      filtersExpanded: false,
    };
  },
  methods: {
    getColClass(r, c) {
      if (c.e === 'offer') {
        return 'col-offer-status-' + r.offer_final_status;
      }
      if (c.e === 'placement') {
        return 'col-offer-status-' + r.placement_final_status;
      }
      return '';
    },
    getRowClass(r) {
      if (this.filters.group_by === 'offer') {
        return 'row-placement-status-' + r.offer_final_status;
      }
      if (this.filters.group_by === 'default' || this.filters.group_by === 'placement') {
        return 'row-placement-status-' + r.placement_final_status;
      }
      return '';
    },
    toggleFilters: function () {
      this.filtersExpanded = !this.filtersExpanded;
    },
    doSort() {
      console.log('sort');
      this.loadData();
    },
    getURLFiltersParams(record, moreParams) {
      let params = {
        timezone: this.filters.datePicker.timezone,
        placement_id: this.filters.placement_id,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'), //.format('YYYY-MM-DD'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'), //.format('YYYY-MM-DD'),
      };
      if (typeof moreParams !== 'undefined' && moreParams) {
        Object.assign(params, moreParams);
      }

      switch (this.filters.group_by) {
        case 'subsource':
          params.subsource_id = record.subsource_id;
          break;
        case 'subsource2':
          params.sub2_id = record.sub2_id;
          break;
        case 'adv_subsource':
          params.adv_sub1_id = record.adv_sub1_id;
          break;
      }
      return params;
    },

    async showRulesModal(r) {
      let source = {
        id: r.subsource_id,
        placement_id: r.placement_id,
        sub_id: r.subsource_name,
      };
      let resp = await new Promise((resolve) => {
        this.$modal.show(
          SubsourceRulesModal,
          {
            rules: r.routes,
            subsource: source,
            publisher: this.publisher,
            placement: this.placement,
          },
          {
            height: '90%',
            width: 800,
          },
          {
            'before-close': async (/*event*/) => {
              resolve(r);
            },
          },
        );
      });
      let routes = Vue.util.extend([], resp.routes);
      console.log(routes);
      Vue.set(this.routes, r.subsource_id, routes);
      Vue.set(r, 'routes', routes);
      // this.routes[r.subsource_id] = routes;
      this._calcSubsourceRoutes(r);
      // this.$emit('update:routes', this.routes);
      // this.$emit('update:records', this.records);
      // this.$emit('update:pages', this.pages);
      // Vue.set(this, 'routes', this.routes);
      // Vue.set(this, 'records', this.records);
    },

    async toggleSubsource(r, status) {
      let params = {
        subsource_id: r.subsource_id,
        placement_id: r.placement_id,
        status: status,
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.post('placement/toggleSubsourceStatus', params);

        if (resp.action === 'add') {
          r.status = 'paused';
          r.default_rule = true;
          this.routes[resp.route.from_subsource_id] = [resp.route];
          r.routes = [this.routes[resp.route.from_subsource_id]];
          this._calcSubsourceRoutes(r);
        } else if (resp.action === 'remove') {
          r.status = 'active';
          r.default_rule = false;
          r.routes = [];
          delete this.routes[resp.route.from_subsource_id];
        }
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async loadData(doExport) {
      let params = {
        export: doExport ? 1 : 0,
        page: this.paginate.page,
        page_size: this.paginate.limit,
        group_by: this.filters.group_by,
        offer_id: this.offer.id,
        publisher_id: this.filters.publisher_id.map((r) => r.v).join(','),
        // click_type: this.filters.click_type ? 1 : 0,
        // dimensions: this.filters.dimensions.map(r => r.v).join(','),
        timezone: this.filters.datePicker.timezone,
        date_start: moment(this.filters.datePicker.dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        date_end: moment(this.filters.datePicker.dateRange.endDate).format('YYYY-MM-DD HH:mm'),
        sort_col: this.sortBy.field,
        sort_dir: this.sortBy.direction,
      };
      this.busy = true;
      try {
        let resp = await this.$ovReq.get('statistics/getList_ch', { params });
        if (doExport) {
          this.busy = false;
          window.open(resp.path);
        } else {
          this.columns = resp.columns.filter((c) => {
            if (['offer', 'advertiser'].indexOf(c.e) > -1) {
              return false;
            }
            return true;
          });

          this.records = resp.records;
          this.records.forEach((r) => {
            r.status = 'active';
            r.status_desc = [];
            r.routes = [];
          });
          this.summary = resp.summary;
          this.paginate.total = resp.total;
          this.paginate.numPages = Math.max(
            Math.ceil(this.paginate.total / this.paginate.limit),
            1,
          );
          this.busy = false;
          this.loadRoutes();
        }
      } catch (e) {
        console.error(e);
        this.busy = false;
      }
    },
    async loadRoutes() {
      return;
      //this.busy = true;
      /*try {
					let resp = await this.$ovReq.get('offer/getRoutes/' + this.offer.id);
					// Vue.set(this, 'routes', []);
					let routes = {};
					this.busy = false;
					this.blacklist = resp.blacklist;
					this.blacklistHash = {};
					this.blacklist.forEach(b => {
						this.blacklistHash[b] = true;
					});
					resp.routes.forEach(r => {
						if (typeof (routes[r.from_subsource_id]) !== 'undefined') {
							routes[r.from_subsource_id].push(r);
						} else {
							routes[r.from_subsource_id] = [r];
						}
					});

					if (this.filters.group_by === 'subsource') {
						this.records.forEach(r => {
							this._calcSubsourceRoutes(r);
						});
					}
					this.routes = routes;
					// Vue.set(this, 'routes', routes);
					// console.log('-----', this.routes);
				} catch (e){
					console.error(e);
				}
				this.busy = false;*/
    },

    _calcSubsourceRoutes(record) {
      // console.log(record);
      record.status = 'active';
      record.status_desc = [];
      if (this.routes[record.subsource_id]) {
        record.routes = this.routes[record.subsource_id];
        record.routes.forEach((rt) => {
          if (rt.weight === 100 && rt.action === 'global_fallback') {
            record.status = 'paused';
            record.default_rule = true;
            //r.status_desc
          }
        });
      }
      if (this.blacklistHash[record.subsource_id]) {
        record.blacklist = true;
      } else {
        record.blacklist = false;
      }
    },
    // async toggleSubsource(r, status) {
    //
    // }
  },
};
</script>
